import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme & CssVarsTheme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.caption,

      marginTop: theme.spacing(1),
      color: 'inherit',
      [`&.MuiFormHelperText-filled`]: {
        color: 'inherit',
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },
      '&.Mui-focused': {
        color: 'inherit',
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },
    }),
  },
};
