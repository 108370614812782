import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiList: Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme>['MuiList'] =
  {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        '& .MuiTypography-root': {
          color: theme.palette.common.black,
          fontSize: theme.spacing(1.75),
          fontWeight: 400,
          lineHeight: theme.spacing(2.5),
        },
        '& .MuiListItem-root': {
          padding: `${theme.spacing(1.5)} 0`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          '&:first-of-type': {
            paddingTop: 0,
          },
        },
        '& .MuiListItem-dense': {
          padding: `${theme.spacing(1)} 0`,
        },
      }),
    },
  };
