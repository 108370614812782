import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiIconButton: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette._components.iconButton.default.hover,
        outline: `2px solid ${theme.palette.primary.dark}`,
        outlineOffset: '2px',
      },
      '&.Mui-disabled': {
        border: 'transparent',
        color: theme.palette._components.button.disabledText,
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            minWidth: theme.spacing(5),
            minHeight: theme.spacing(5),
            padding: theme.spacing(1.15),
          },
        },
        {
          props: { size: 'medium' },
          style: {
            minWidth: theme.spacing(4.5),
            minHeight: theme.spacing(4.5),
            padding: theme.spacing(0.65),
          },
        },
        {
          props: { size: 'small' },
          style: {
            minWidth: theme.spacing(3.75),
            minHeight: theme.spacing(3.75),
            padding: theme.spacing(0.9),
          },
        },
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: 'transparent',
            color: theme.palette._components.iconButton.primary.text,
            border: `1px solid ${theme.palette._components.button.primary.contained}`,
            '&:hover': { backgroundColor: theme.palette._components.iconButton.primary.hover },
            '&.Mui-focusVisible': {
              borderColor: 'transparent',
              backgroundColor: theme.palette._components.iconButton.primary.focused,
              color: theme.palette._components.iconButton.primary.focusedIcon,
            },
            '&:active': {
              backgroundColor: theme.palette._components.button.primary.focused,
              borderColor: theme.palette._components.button.primary.contained,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: 'transparent',
            color: theme.palette._components.iconButton.secondary.text,
            border: `1px solid ${theme.palette._components.button.secondary.border}`,
            '&:hover': {
              backgroundColor: theme.palette._components.iconButton.secondary.hover,
            },
            '&.Mui-focusVisible': {
              borderColor: 'transparent',
              backgroundColor: theme.palette._components.iconButton.secondary.focused,
            },
            '&:active': {
              backgroundColor: theme.palette._components.button.secondary.pressed,
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: 'transparent',
            color: theme.palette._components.button.error.focused,
            border: `1px solid ${theme.palette._components.button.error.border}`,
            '&:hover': {
              backgroundColor: theme.palette._components.button.error.outlinedHover,
            },
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette._components.iconButton.error.focused,
            },
            '&:active': {
              backgroundColor: theme.palette._components.iconButton.error.focused,
            },
          },
        },
      ],
    }),
  },
};
