import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiDialog: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.spacing(1.5),
      '& form': {
        marginBlockEnd: 0,
      },
    }),
  },
};

//

export const MuiDialogTitle: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiDialogTitle'] = {
  defaultProps: {
    variant: 'h5',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2),
    }),
  },
};

//

export const MuiDialogContent: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2),
      ':not(:first-child)': {
        paddingTop: 0,
        marginTop: theme.spacing(1.5),
      },
      ':not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
    }),
  },
};

//

export const MuiDialogActions: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    }),
  },
};
