import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';

// import { colorSchemes } from './color-schemes';
import { components } from './components';
import { palette } from './palette';
import { shadows } from './shadows';
// import { spacing } from './spacing';
import { typography } from './typography';

//
//

export const themeParcel = createTheme({
  shape: {
    borderRadius: 8,
    standardBorderRadius: 12,
    // cardBorderRadius: 16,
    // sectionBorderRadius: 24,
    // buttonBorderRadius: 360,
  },
  // spacing,
  // colorSchemes,
  shadows,
  palette,
  typography,
  components,
});
