/* eslint-disable max-lines */
import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiTable: Components<Theme & CssVarsTheme>['MuiTable'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',

      '.MuiTableHead-root': {
        '.MuiTableCell-root': {
          borderBottom: `1px solid ${theme.palette.background.gray200}`,
          borderTop: `1px solid ${theme.palette.background.gray200}`,
          background: theme.palette.background.gray,
        },
      },

      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          '.MuiTableCell-root': {
            borderBottom: `1px solid ${theme.palette.background.gray200}`,
            borderTop: `1px solid ${theme.palette.background.gray200}`,
          },
        },

        '.MuiTableRow-root:last-child': {
          '.MuiTableCell-root': {
            borderBottom: 'none',
          },
        },

        '.MuiTableRow-root:hover': {
          '.MuiTableCell-root': {
            backgroundColor: '#FBFBFB',
          },
        },
      },
    }),
  },
};
