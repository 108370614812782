import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiLoadingButton: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiLoadingButton'] = {
  defaultProps: {
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: () => ({
      '&.Mui-disabled': {
        '.MuiLoadingButton-label': {
          visibility: 'hidden',
        },
      },
      '.MuiLoadingButton-Label': {
        // Truncate text with ellipsis if it overflows the button width
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }),
  },
};
